import React, {FC, useContext} from "react";
import Slider from '@components/common/Slider'
import {
  BuilderBlockComponent,
  BuilderBlocks,
  BuilderElement,
  BuilderStoreContext,
} from '@builder.io/react';
import {NextArrow, PrevArrow} from "@components/common/CarouselArrow";
import {Box} from "theme-ui";
import {getCollections} from "@lib/helpers";


interface CarouselProps {
  attributes: any;
  builderBlock: any
  className: string
  centerMode: boolean
  infinite: boolean
  centerPadding: string
  slidesToShow: number
  autoplay: boolean
  autoplaySpeed: number
  variableWidth: boolean
  responsive: {
    breakpoint: number
    settings: {
      slidesToShow: number
      className: string
      centerMode: boolean
    }
  }[]
  nextButton?: BuilderElement[];
  prevButton?: BuilderElement[];
}

export const Carousel:FC<CarouselProps> = (props) => {
  const {
    attributes: { key, ...otherAtt },
    builderBlock,
    className,
    centerMode,
    centerPadding,
    autoplaySpeed,
    slidesToShow,
    autoplay,
    responsive,
    infinite,
    variableWidth
  } = props

  // console.log('Carousel', props)
  const ctx = useContext(BuilderStoreContext)

  return (
      <Box key={key} {...otherAtt}>
        <Slider
            className={className} //slider variable-width
            centerMode={centerMode}
            centerPadding={centerPadding}
            autoplaySpeed={autoplaySpeed}
            slidesToShow={slidesToShow}
            autoplay={autoplay}
            responsive={responsive}
            variableWidth={variableWidth}
            infinite={infinite}
            nextArrow={
              <NextArrow>
                <BuilderBlocks
                    parentElementId={builderBlock.id}
                    dataPath="component.options.prevButton"
                    blocks={props.nextButton}
                />
              </NextArrow>
            }
            prevArrow={
              <PrevArrow>
                <BuilderBlocks
                    parentElementId={builderBlock.id}
                    dataPath="component.options.nextButton"
                    blocks={props.prevButton}
                />
              </PrevArrow>
            }
        >
          {
            builderBlock.children && builderBlock.children.map((block: BuilderElement, index: number) => {
              if (block.repeat && block.repeat.collection) {

                const {collection, itemName} = getCollections(block.repeat, ctx)

                return collection?.map((data, index) => {
                  const childState = {
                    ...ctx.state,
                    [itemName]: data,
                    $index: index,
                    $item: data,
                  };

                  return (
                      <BuilderStoreContext.Provider
                          key={block.id}
                          value={{ ...ctx, state: childState } as any}
                      >
                        <BuilderBlockComponent
                            block={{
                              ...block,
                              repeat: null,
                            }}
                            index={index}
                            child
                        />
                      </BuilderStoreContext.Provider>
                  );
                });
              }
              return (
                <BuilderBlockComponent
                    key={block.id}
                    block={block}
                    index={index}
                    child
                />
              )
            })
          }
        </Slider>
      </Box>
  )
}
