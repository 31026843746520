import React from "react";
import {Box} from "theme-ui";


export const NextArrow = ({ onClick, children }: any) => (
    <Box
        color={'muted'}
        className={'carousel-arrow'}
        style={{ right: 0}}
        onClick={onClick}
    >
      {children ? children : <i className="bi bi-arrow-right-circle" style={{ fontSize: '40px', paddingRight: '20px'}} />}
    </Box>
)

export const PrevArrow = ({ onClick,sx, children }: any) => (
    <Box
        color={'muted'}
        className={'carousel-arrow'}
        style={{ left: 0}}
        onClick={onClick}
    >
      {children ? children : <i className="bi bi-arrow-left-circle" style={{ fontSize: '40px', paddingLeft: '20px'}} />}
    </Box>
)