
interface Collections {
  itemName: string
  collection?: Array<any>
}

interface BlockRepeat {
  collection: string
  itemName?: string
}

export const getCollections = (blockRepeat: BlockRepeat, context: any):Collections => {
  const p: Array<string> = blockRepeat.collection.split('.')
  let c = context
  do {
    const key = p.shift()
    if(key && c?.[key]){
      c = c?.[key]
    }
  }while (p.length)

  const collectionName = (blockRepeat.collection || '').split(/\.\w+\(/)[0].trim().split('.').pop();
  const itemName = blockRepeat.itemName || (collectionName ? collectionName + 'Item' : 'item');

  return {itemName: itemName, collection: c};
}
